<template>
  <div class="reference-number-form">
    <div class="reference-number-input">
      <v-text-field
        :label="$t(`header.referenceNumberForm.label`)"
        v-model="referenceNumber"
        filled
        rounded
        dense
        height="20px"
        :error="loadMissingError || loadOldError"
        :rules="[
          !loadOldError || $t('error.projectOld'),
          !loadMissingError || $t('error.projectNotFound'),
          referenceNumberValid || $t('error.refNumberNotValid')
        ]"
      ></v-text-field>
    </div>
    <div class="reference-number-button-submit">
      <v-btn
        depressed
        color="primary"
        :disabled="!referenceNumberValid"
        v-on:click="load()"
      >
        Load
      </v-btn>
    </div>
  </div>
</template>

<script>
import router from '../../plugins/router';

export default {
  name: 'ReferenceNumberForm',
  data () {
    return {
      referenceNumber: "",
      loadMissingError: false,
      loadOldError: false,
    }
  },
  computed: {
    referenceNumberValid() {
        const refIdString = String(this.referenceNumber);
        if (refIdString === '') {
            return null;
        }
        if (!refIdString.match(/^JF-[A-Za-z0-9]{13}$/gm)) {
            return false;
        }
        return true;
    },
  },
  methods: {
    load() {
      this.$store.dispatch("api/loadApplicationData", this.referenceNumber)
        .then(() => {
          router.push({ name: 'configurator' });
        }).catch((e) => {
          this.loadMissingError = false;
          this.loadOldError = false;
          
          if(e.message == "missing-project") {
            this.loadMissingError = true; 
          } else if (e.message == "old-project") {
            this.loadOldError = true;
          }
        });
    }
  },
}
</script>
<style lang="scss" scoped>
  .reference-number-form {
    height: auto;
    display: flex;
    align-items: center;
    padding-top: 3vh;
    padding-left: 9vw; 
    text-align:center;
  }

  .reference-number-input {
    width: 260px;
    margin-right: 10px;
    height: 50px;
  }

  .reference-number-button-submit {
    width: 60px;
  }
</style>