<template>
  <div class="home full-height">
    <v-container class="white lighten-5 full-height" fluid>
      <v-row class="inherit-height">
        <v-col
          md="6"
          cols="12"
        >
          <div class="content-left-container">
              <div class="main-title" v-html="$t(`home.title`)">
              </div>
              <div class="subtitle">
                {{ $t(`home.subtitle`) }}
              </div>
              <router-link :to="{ name: 'basic-data' }">
                  <div class="start-button">
                     {{ $t(`home.startButton`) }}
                  </div>
              </router-link>
              <div class="or-text">
                {{ $t(`home.or`) }}
              </div>
              <div class="reference-number-form">
                <reference-number-form></reference-number-form>
              </div>
          </div>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
        <div class="content-right-container">
          <v-row class="no-padding1">
            <v-col
              md="4"
              cols="12"
            >
              <div class="images-container c1">
                <div class="image-box">
                  <img src="../assets/images/home/1.jpg" class="representation-image">
                </div>
                <div class="image-box">
                  <img src="../assets/images/home/2.jpg" class="representation-image">
                </div>
                <div class="image-box">
                  <img src="../assets/images/home/3.jpg" class="representation-image">
                </div>
              </div>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <div class="images-container c2">
                <div class="image-box">
                  <img src="../assets/images/home/4.jpg" class="representation-image">
                </div>
                <div class="image-box">
                  <img src="../assets/images/home/5.jpg" class="representation-image">
                </div>
                <div class="image-box">
                  <img src="../assets/images/home/6.jpg" class="representation-image">
                </div>
              </div>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <div class="images-container c3">
                <div class="image-box">
                  <img src="../assets/images/home/7.jpg" class="representation-image">
                </div>
                <div class="image-box">
                  <img src="../assets/images/home/8.jpg" class="representation-image">
                </div>
                <div class="image-box">
                  <img src="../assets/images/home/9.jpg" class="representation-image">
                </div>
              </div>
            </v-col>
          </v-row>
          </div>
        </v-col>
      </v-row>
      <div>
        <span class="text-caption">Version: {{ version }}</span>
      </div>
    </v-container>
  </div>
</template>
<script>

import ReferenceNumberForm from '../components/general/ReferenceNumberForm.vue'

export default {
  name: 'Home',
  components: {
    ReferenceNumberForm
  },
  data () {
    return {
      version: process.env.VUE_APP_COMMIT_HASH
    }
  },
}
</script>